.article {
    background-color: #fafbfe;
    border-radius: 3px;
    border: 1px solid #dae1f4;
    position: relative;
    margin-bottom: 30px;
}
.article .article-header {
    height: 170px;
    position: relative;
    overflow: hidden;
}
.article .article-header .article-image {
    background-color: #fbfbfb;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.article .article-header .article-title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom,  rgba(0, 0, 0, 0) 0%,  rgba(0, 0, 0, 0.01) 1%,  rgba(0, 0, 0, 0.65) 98%,  rgba(0, 0, 0, 0.65) 100%);
    padding: 10px;
}
.article .article-header .article-title h2 {
    font-size: 16px;
    line-height: 24px;
}
.article .article-header .article-title h2 a {
    font-weight: 700;
    text-decoration: none;
    color: #fff;
}
.article .article-details {
    background-color: #fff;
    padding: 20px;
    line-height: 24px;
}
.article .article-details .article-cta {
    text-align: center;
}
.article .article-header .article-badge {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
.article .article-header .article-badge .article-badge-item {
    padding: 7px 15px;
    font-weight: 600;
    color: #fff;
    border-radius: 30px;
    font-size: 12px;
}
.article .article-header .article-badge .article-badge-item .ion, .article .article-header .article-badge .article-badge-item .fas, .article .article-header .article-badge .article-badge-item .far, .article .article-header .article-badge .article-badge-item .fab, .article .article-header .article-badge .article-badge-item .fal {
    margin-right: 3px;
}
.article.article-style-b .article-details .article-title {
    margin-bottom: 10px;
}
.article.article-style-b .article-details .article-title h2 {
    line-height: 22px;
}
.article.article-style-b .article-details .article-title a {
    font-size: 16px;
    font-weight: 600;
}
.article.article-style-b .article-details p {
    color: #34395e;
}
.article.article-style-b .article-details .article-cta {
    text-align: right;
}
.article.article-style-c .article-header {
    height: 233px;
}
.article.article-style-c .article-details .article-category {
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 1px;
    color: #34395e;
}
.article.article-style-c .article-details .article-category a {
    font-size: 10px;
    color: #34395e;
    font-weight: 700;
}
.article.article-style-c .article-details .article-title {
    margin-bottom: 10px;
}
.article.article-style-c .article-details .article-title h2 {
    line-height: 22px;
}
.article.article-style-c .article-details .article-title a {
    font-size: 16px;
    font-weight: 600;
}
.article.article-style-c .article-details p {
    color: #34395e;
}
.article.article-style-c .article-user {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
}
.article.article-style-c .article-user img {
    border-radius: 50%;
    float: left;
    width: 45px;
    margin-right: 15px;
}
.article.article-style-c .article-user .user-detail-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.article.article-style-c .article-user .user-detail-name a {
    font-weight: 700;
}
@media (max-width: 575.98px) {
    .article .article-style-c .article-header {
    height: 225px;
}
}@media (min-width: 768px) and (max-width: 991.98px) {
    .article {
    margin-bottom: 40px;
}
.article .article-header {
    height: 195px !important;
}
.article.article-style-c .article-header {
    height: 155px;
}
}@media (max-width: 1024px) {
    .article.article-style-c .article-header {
    height: 216px;
}
.article .article-header {
    height: 155px;
}
}.author-box .author-box-left {
    float: left;
    text-align: center;
    padding-left: 5px;
}
.author-box .author-box-left .btn {
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 30px;
}
.author-box .author-box-picture {
    width: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.author-box .author-box-details {
    margin-left: 135px;
}
.author-box .author-box-name {
    font-size: 18px;
}
.author-box .author-box-name a {
    font-weight: 600;
}
.author-box .author-box-job {
    font-weight: 600;
    letter-spacing: .5px;
    font-size: 12px;
    color: #34395e;
}
.author-box .author-box-description {
    line-height: 26px;
    margin-top: 15px;
}
@media (max-width: 575.98px) {
    .author-box .author-box-left {
    float: none;
}
.author-box .author-box-details {
    margin-left: 0;
    margin-top: 15px;
    text-align: center;
}
}.avatar-item {
    position: relative;
    margin-bottom: 20px;
}
.avatar-item img {
    border-radius: 50%}
.avatar-item .avatar-badge {
    position: absolute;
    bottom: -5px;
    right: 0;
    background-color: #fff;
    color: #000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    width: 25px;
    height: 25px;
}
.browser {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-size: 100%}
.browser.browser-chrome {
    background-image: url("../img/browsers/chrome.png");
}
.browser.browser-firefox {
    background-image: url("../img/browsers/firefox.png");
}
.browser.browser-internet-explorer {
    background-image: url("../img/browsers/internet-explorer.png");
}
.browser.browser-opera {
    background-image: url("../img/browsers/opera.png");
}
.browser.browser-safari {
    background-image: url("../img/browsers/safari.png");
}
.chat-box .chat-content {
    background-color: #f9f9f9 !important;
    height: 300px;
    overflow: hidden;
    padding-top: 25px !important;
}
.chat-box .chat-content .chat-item {
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
}
.chat-box .chat-content .chat-item.chat-right img {
    float: right;
}
.chat-box .chat-content .chat-item.chat-right .chat-details {
    margin-left: 0;
    margin-right: 70px;
    text-align: right;
}
.chat-box .chat-content .chat-item.chat-right .chat-details .chat-text {
    text-align: left;
    background-color: #563d7c;
    color: #fff;
}
.chat-box .chat-content .chat-item>img {
    float: left;
    width: 50px;
    border-radius: 50%}
.chat-box .chat-content .chat-item .chat-details {
    margin-left: 70px;
}
.chat-box .chat-content .chat-item .chat-details .chat-text {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #fff;
    padding: 10px 15px;
    border-radius: 3px;
    width: auto;
    display: inline-block;
    font-size: 12px;
}
.chat-box .chat-content .chat-item .chat-details .chat-text img {
    max-width: 100%;
    margin-bottom: 10px;
}
.chat-box .chat-content .chat-item.chat-typing .chat-details .chat-text {
    background-image: url("http://puffintheme.com/craft/codiepie/dist/assets/img/typing.svg");
    height: 40px;
    width: 60px;
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
}
.chat-box .chat-content .chat-item .chat-details .chat-time {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 500;
    opacity: .6;
}
.chat-box .chat-form {
    padding: 0;
    position: relative;
}
.chat-box .chat-form .form-control {
    border: none;
    padding: 15px;
    height: 50px;
    padding-right: 70px;
    font-size: 13px;
    font-weight: 500;
    box-shadow: none;
    outline: none;
}
.chat-box .chat-form .btn {
    padding: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -5px;
    -webkit-transform: translate(-50%,  -50%);
    transform: translate(-50%,  -50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.chat-box .chat-form .btn i {
    margin-left: 0;
}
.chocolat-wrapper {
    z-index: 890;
}
.chocolat-overlay {
    background-color: #000;
}
[data-tab-group] {
    display: none;
}
[data-tab-group].active {
    display: block;
}
table.dataTable {
    border-collapse: collapse !important;
}
table.dataTable thead th, table.dataTable thead td {
    border-bottom: 1px solid #ddd !important;
}
table.dataTable.no-footer {
    border-bottom: 1px solid #ddd !important;
}
.dataTables_wrapper {
    padding: 0 !important;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 !important;
    margin: 0 !important;
    float: left;
}
div.dataTables_wrapper div.dataTables_processing {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 0 !important;
    background-image: url("http://puffintheme.com/craft/codiepie/dist/assets/img/spinner.svg") !important;
    background-color: #fff;
    background-size: 100%;
    width: 50px !important;
    height: 50px;
    border: none;
    top: 50% !important;
    left: 50% !important;
    -webkit-transform: translate(-50%,  -50%) !important;
    transform: translate(-50%,  -50%) !important;
    margin: 0 !important;
    opacity: 1 !important;
}
.daterangepicker.dropdown-menu {
    width: auto;
}
.daterangepicker .input-mini {
    padding-left: 28px !important;
}
.daterangepicker .calendar th, .daterangepicker .calendar td {
    padding: 5px;
    font-size: 12px;
}
.ranges li {
    color: #563d7c;
}
.ranges li:hover, .ranges li.active {
    background-color: #563d7c;
}
.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #563d7c;
}
.dropzone {
    border: 2px dashed #563d7c;
    min-height: 240px;
    text-align: center;
}
.dropzone .dz-message {
    font-size: 24px;
    color: #34395e;
    margin: 3.4em;
}
.dropzone .dz-preview .dz-details {
    padding: 2.2em 1em;
}
.dropzone .dz-preview .dz-image {
    border-radius: 3px;
}
@media (max-width: 575.98px) {
    .dropzone .dz-message {
    margin: 2em;
}
}@media (min-width: 576px) and (max-width: 767.98px) {
    .dropzone .dz-message {
    margin: 2.75em;
}
}.flag-icon {
    width: 50px;
    height: 35px;
    display: inline-block;
    background-size: 100%}
.flag-icon.flag-icon-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.fc-toolbar h2 {
    font-size: 16px;
    margin-top: 4px;
}
.fc-view {
    border-color: #f2f2f2;
    color: #34395e !important;
    font-weight: 500;
    padding: 10px;
}
.fc-view>table {
    border-color: #f2f2f2;
}
.fc-view>table tr, .fc-view>table td {
    border-color: #f2f2f2;
}
.fc-view>table th {
    border-color: #f2f2f2;
    color: #34395e !important;
    font-weight: 500;
    padding: 10px;
}
.fc-view-container>.fc-view {
    padding: 0;
}
.fc-view {
    color: #666;
    text-align: right;
}
.fc-view>table td {
    color: #666;
    text-align: right;
}
.fc-unthemed td.fc-today {
    background-color: #f2f2f2;
}
.fc button .fc-icon {
    top: -0.09em;
}
.fc-basic-view .fc-day-number, .fc-basic-view .fc-week-number {
    padding: 10px;
}
.fc-day-grid-event .fc-content {
    padding: 5px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
tr:first-child>td>.fc-day-grid-event {
    margin-bottom: 10px;
}
.fc-state-default {
    border-radius: 3px;
    background-color: #f2f2f2;
    background-image: none;
    border: none;
    box-shadow: none;
    text-transform: capitalize;
    font-weight: 500;
}
.fc button {
    height: auto;
    padding: 10px 15px;
    text-shadow: none;
    border-radius: 0;
}
.fc button.fc-state-active {
    background-color: #563d7c;
    color: #fff;
}
.gallery {
    display: inline-block;
    width: 100%}
.gallery .gallery-item {
    float: left;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    margin-right: 7px;
    margin-bottom: 7px;
    cursor: pointer;
    transition: all .5s;
    position: relative;
}
.gallery .gallery-item:hover {
    opacity: .8;
}
.gallery .gallery-hide {
    display: none;
}
.gallery .gallery-more:after {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}
.gallery .gallery-more div {
    text-align: center;
    line-height: 50px;
    font-weight: 600;
    position: relative;
    z-index: 2;
    color: #fff;
}
.gallery.gallery-md .gallery-item {
    width: 78px;
    height: 78px;
    margin-right: 10px;
    margin-bottom: 10px;
}
.gallery.gallery-md .gallery-more div {
    line-height: 78px;
}
.gallery.gallery-fw .gallery-item {
    width: 100%;
    margin-bottom: 15px;
}
.gallery.gallery-fw .gallery-more div {
    font-size: 20px;
}
.image-preview, #callback-preview {
    width: 250px;
    height: 250px;
    border: 2px dashed #ddd;
    border-radius: 3px;
    position: relative;
    overflow: hidden;
    background-color: #ffffff;
    color: #ecf0f1;
}
.image-preview input, #callback-preview input {
    line-height: 200px;
    font-size: 200px;
    position: absolute;
    opacity: 0;
    z-index: 10;
}
.image-preview label, #callback-preview label {
    position: absolute;
    z-index: 5;
    opacity: 0.8;
    cursor: pointer;
    background-color: #bdc3c7;
    width: 150px;
    height: 50px;
    font-size: 12px;
    line-height: 50px;
    text-transform: uppercase;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
}
.audio-preview {
    background: #ffffff;
    width: auto;
    padding: 20px;
    display: inline-block;
}
.audio-upload {
    cursor: pointer;
    background-color: #bdc3c7;
    color: #ecf0f1;
    padding: 20px;
    font-size: 20px;
    text-transform: uppercase;
}
.ionicons {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
}
.ionicons li {
    width: calc(100% / 8);
    font-size: 40px;
    padding: 40px 20px;
    list-style: none;
    text-align: center;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}
.ionicons li:hover {
    opacity: .8;
}
.ionicons li .icon-name {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%,  -100%);
    transform: translate(-50%,  -100%);
    font-family: 'Segoe UI';
    font-size: 12px;
    margin-top: 10px;
    line-height: 22px;
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 10px;
    display: none;
}
.jqvmap-circle {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: #fff;
    border: 3px solid #563d7c;
    border-radius: 50%}
.jqvmap-label {
    z-index: 889;
}
.jqvmap-zoomin, .jqvmap-zoomout {
    height: auto;
    width: auto;
}
.profile-widget {
    margin-top: 35px;
}
.profile-widget .profile-widget-picture {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    float: left;
    width: 100px;
    margin: -35px -5px 0 30px;
    position: relative;
    z-index: 1;
}
.profile-widget .profile-widget-header {
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
}
.profile-widget .profile-widget-items {
    display: flex;
    position: relative;
}
.profile-widget .profile-widget-items:after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: -25px;
    right: 0;
    height: 1px;
    background-color: #f2f2f2;
}
.profile-widget .profile-widget-items .profile-widget-item {
    flex: 1;
    text-align: center;
    border-right: 1px solid #f2f2f2;
    padding: 10px 0;
}
.profile-widget .profile-widget-items .profile-widget-item:last-child {
    border-right: none;
}
.profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-label {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: .5px;
    color: #34395e;
}
.profile-widget .profile-widget-items .profile-widget-item .profile-widget-item-value {
    color: #000;
    font-weight: 600;
    font-size: 16px;
}
.profile-widget .profile-widget-description {
    padding: 20px;
    line-height: 26px;
}
.profile-widget .profile-widget-description .profile-widget-name {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
}
@media (max-width: 575.98px) {
    .profile-widget .profile-widget-picture {
    left: 50%;
    -webkit-transform: translate(-50%,  0);
    transform: translate(-50%,  0);
    margin: 40px 0;
    float: none;
}
.profile-widget .profile-widget-items .profile-widget-item {
    border-top: 1px solid #f2f2f2;
}
}.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    outline: none;
    box-shadow: none;
}
.select2-container .select2-selection--multiple, .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 42px;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    outline: none;
    background-color: #aa94ca;
    border-color: #9b81c1;
}
.select2-dropdown {
    border-color: #9b81c1 !important;
}
.select2-container.select2-container--open .select2-selection--multiple {
    background-color: #ab95cb;
    border-color: #6e4e9e;
}
.select2-container.select2-container--focus .select2-selection--multiple, .select2-container.select2-container--focus .select2-selection--single {
    background-color: #ab95cb;
    border-color: #6e4e9e;
}
.select2-container.select2-container--open .select2-selection--single {
    background-color: #ab95cb;
    border-color: #6e4e9e;
}
.select2-results__option {
    padding: 10px;
}
.select2-search--dropdown .select2-search__field {
    padding: 7px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    min-height: 42px;
    line-height: 42px;
    padding-left: 20px;
    padding-right: 20px;
}
.select2-container--default .select2-selection--multiple .select2-selection__arrow, .select2-container--default .select2-selection--single .select2-selection__arrow {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 40px;
    min-height: 42px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #fff;
    padding-left: 10px;
    padding-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
    padding-left: 10px;
    padding-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    margin-right: 5px;
    color: #fff;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice, .select2-container--default .select2-results__option[aria-selected=true], .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #563d7c;
    color: #fff;
}
.select2-results__option {
    padding-right: 10px 15px;
}
.selectric {
    background-color: #aa94ca;
    border-color: #9b81c1;
    min-height: 42px;
    border-radius: 3px;
    padding-left: 10px;
    padding-right: 10px;
}
.selectric:hover {
    background-color: #aa94ca;
    border-color: #9b81c1;
}
.selectric:focus {
    background-color: #ab95cb;
    border-color: #6e4e9e;
}
.selectric .label {
    font-size: 13px;
    background-color: transparent;
    line-height: 44px;
    min-height: 42px;
}
.selectric .button {
    background-color: transparent;
    line-height: 44px;
    min-height: 42px;
}
.selectric-open .selectric {
    border-color: #563d7c;
}
.selectric-above .selectric-items, .selectric-below .selectric-items {
    margin-bottom: 10px;
}
.selectric-items {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    background-color: #fff;
    border: none;
}
.selectric-items li {
    font-size: 13px;
    padding: 10px 15px;
}
.selectric-items li:hover {
    background-color: #f2f2f2;
}
.selectric-items li.selected, .selectric-items li.highlighted {
    background-color: #563d7c;
    color: #fff;
}
.slider .owl-nav [class*=owl-] {
    position: absolute;
    top: 50%;
    left: 35px;
    -webkit-transform: translate(-50%,  -50%);
    transform: translate(-50%,  -50%);
    margin: 0;
    background-color: #000;
    border-radius: 50%;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 34px;
    opacity: .3;
}
.slider .owl-nav [class*=owl-]:hover {
    background-color: #000;
}
.slider .owl-nav .owl-next {
    right: 0;
    left: initial;
}
.slider:hover .owl-nav [class*=owl-] {
    opacity: 1;
}
.slider .slider-caption {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    padding: 10px;
}
.slider .slider-caption .slider-title {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}
.slider .slider-caption .slider-description {
    line-height: 26px;
    opacity: .8;
}
.jqstooltip {
    box-sizing: content-box;
}
.sparkline-bar, .sparkline-line, .sparkline-inline {
    width: 100%}
.sparkline-bar canvas, .sparkline-line canvas, .sparkline-inline canvas {
    width: 100% !important;
}
.statistic-details {
    display: flex;
    flex-wrap: wrap;
}
.statistic-details .statistic-details-item {
    flex: 1;
    padding: 17px 10px;
    text-align: center;
}
.statistic-details .statistic-details-item .detail-chart {
    margin-bottom: 10px;
    padding: 0 20px;
}
.statistic-details .statistic-details-item .detail-name {
    font-size: 12px;
    margin-top: 5px;
    color: #34395e;
    letter-spacing: .3px;
}
.statistic-details .statistic-details-item .detail-value {
    font-size: 18px;
    font-weight: 700;
}
@media (max-width: 575.98px) {
    .statistic-details {
    flex-wrap: wrap;
}
.statistic-details .statistic-details-item {
    flex: initial;
    width: 50%}
}.summary {
    display: inline-block;
    width: 100%}
.summary .summary-info {
    background-color: #eaf2f4;
    padding: 50px 0;
    text-align: center;
    border-radius: 3px;
}
.summary .summary-info h4 {
    font-weight: 600;
}
.summary .summary-item {
    margin-top: 20px;
}
.summary .summary-item h6 {
    font-size: 12px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 20px;
}
.note-editor.note-frame {
    border-radius: 3px;
    border: 1px solid #ededed;
    box-shadow: none;
}
.note-toolbar {
    padding: 0 0 5px 5px !important;
    position: relative !important;
}
.note-toolbar.card-header {
    height: auto;
    display: block;
    min-height: auto;
}
.note-toolbar .note-btn {
    font-size: 12px;
    background-color: transparent;
    box-shadow: none;
    border-color: transparent;
}
.swal-button {
    border-radius: 3px;
    font-size: 16px;
}
.swal-button:focus {
    box-shadow: none;
}
.swal-button.swal-button--confirm {
    box-shadow: 0 2px 6px #7a58ad;
    background-color: #563d7c;
}
.swal-button.swal-button--confirm:focus {
    opacity: .8;
}
.swal-footer {
    text-align: center;
}
.swal-text {
    text-align: center;
    line-height: 24px;
    font-weight: 500;
}
.bootstrap-tagsinput {
    background-color: #aa94ca;
    border-color: #9b81c1;
    display: block;
    height: 46px;
    box-shadow: none;
    overflow: auto;
}
.bootstrap-tagsinput input {
    height: 100%;
    padding: 0 8px;
}
.bootstrap-tagsinput .tag {
    background-color: #563d7c;
    border-radius: 3px;
    padding: 5px 10px;
}
.bootstrap-tagsinput .tag:first-child {
    margin-left: 5px;
}
.bootstrap-tagsinput:focus {
    background-color: #ab95cb;
    border-color: #6e4e9e;
}
.bootstrap-timepicker-widget table td a span {
    margin-left: 0 !important;
}
#toast-container>div {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 20px 50px;
    opacity: 1;
}
#toast-container>.toast {
    background-image: none !important;
}
#toast-container>.toast:before {
    position: absolute;
    left: 17px;
    top: 25px;
    font-family: 'Ionicons';
    font-size: 24px;
    line-height: 18px;
    color: #fff;
}
#toast-container>.toast-warning:before {
    content: "\f100"}
#toast-container>.toast-error:before {
    content: "\f2d7"}
#toast-container>.toast-info:before {
    content: "\f44c";
    color: #000;
}
#toast-container>.toast-success:before {
    content: "\f121"}
.toast.toast-error {
    background-color: #D9534F;
}
.toast.toast-warning {
    background-color: #F0AD4E;
}
.toast.toast-success {
    background-color: #5CB85C;
}
.toast.toast-info {
    background-color: #fff;
}
.toast.toast-info .toast-title {
    color: #000;
}
.toast.toast-info .toast-message {
    color: #000;
    margin-top: 5px;
}
.user-item {
    text-align: center;
}
.user-item img {
    border-radius: 50%;
    padding-left: 20px;
    padding-right: 20px;
}
.user-item .user-details {
    margin-top: 10px;
}
.user-item .user-details .user-name {
    font-weight: 600;
    color: #3B3D55;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.user-item .user-details .user-cta {
    margin-top: 10px;
}
.user-item .user-details .user-cta .btn {
    padding: 5px 15px;
    font-size: 12px;
    border-radius: 30px;
}
@media (max-width: 575.98px) {
    .user-progress .media, .user-details .media {
    text-align: center;
    display: inline-block;
    width: 100%}
.user-progress .media img, .user-details .media img {
    margin: 0 !important;
    margin-bottom: 10px !important;
}
.user-progress .media .media-body, .user-details .media .media-body {
    width: 100%}
.user-progress .media .media-items, .user-details .media .media-items {
    margin: 20px 0;
    width: 100%}
.user-progress .list-unstyled-noborder li:last-child, .user-details .list-unstyled-noborder li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}
.user-progress .media .media-progressbar {
    margin-top: 10px;
}
.user-progress .media .media-cta {
    margin-top: 20px;
    margin-left: 0;
}
}.weather .weather-icon {
    float: left;
    width: 150px;
    text-align: center;
    line-height: 40px;
}
.weather .weather-icon span {
    font-size: 60px;
    margin-top: 30px;
}
.weather .weather-desc {
    margin-left: 160px;
}
.weather .weather-desc h4 {
    font-size: 70px;
    font-weight: 200;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 5px;
    line-height: 56px;
}
.weather .weather-desc .weather-text {
    font-size: 12px;
    color: #34395e;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-top: 10px;
}
.weather .weather-desc ul {
    margin: 15px 0 13px 0;
    padding: 0;
}
.weather ul li {
    display: inline-block;
    margin-right: 10px;
    padding: 10px;
    line-height: 1;
    border-radius: 3px;
    border: 2px solid #563d7c;
    font-size: 10px;
    font-weight: 500;
    color: #563d7c;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 10px;
}
@media (max-width: 575.98px) {
    .weather {
    text-align: center;
}
.weather .weather-icon {
    float: none;
    width: auto;
}
.weather .weather-icon span {
    margin-top: 20px;
}
.weather .weather-desc {
    margin-left: 0;
}
}.icon-wrap {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 25px;
    width: calc(100% / 4);
}
.icon-wrap .icon {
    float: left;
    width: 40px;
    font-family: 'weathericons';
    font-size: 20px;
}
.icon-wrap .icon_unicode {
    width: 100%;
    padding-left: 45px;
    color: #34395e;
}
.new-icons ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.new-icons ul li {
    padding: 10px;
}
.icon-wrap .icon, .new-icons ul li .wi {
    font-size: 24px;
    margin-right: 15px;
    width: 30px;
    text-align: center;
}
.pwindicator {
    margin-top: 4px;
    width: 150px;
}
.pwindicator .bar {
    height: 2px;
}
.pw-very-weak .bar {
    background: #d00;
    width: 30px;
}
.pw-very-weak .label {
    color: #d00;
}
.pw-weak .bar {
    background: #d00;
    width: 60px;
}
.pw-weak .label {
    color: #d00;
}
.pw-mediocre .bar {
    background: #f3f01a;
    width: 90px;
}
.pw-mediocre .label {
    color: #f3f01a;
}
.pw-strong .bar {
    background: #f3b31a;
    width: 120px;
}
.pw-strong .label {
    color: #f3b31a;
}
.pw-very-strong .bar {
    background: #0d0;
    width: 150px;
}
.pw-very-strong .label {
    color: #0d0;
}
.product-item {
    text-align: center;
}
.product-item .product-image {
    display: inline-block;
    overflow: hidden;
    width: 80px;
    height: 80px;
    border-radius: 3px;
    margin-bottom: 10px;
}
.product-item .product-name {
    color: #34395e;
    font-weight: 700;
    margin-bottom: 3px;
}
.product-item .product-review {
    color: #F0AD4E;
    margin-bottom: 3px;
}
.product-item .product-cta {
    margin-top: 5px;
}
.product-item .product-cta a {
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.tickets-list .ticket-item {
    text-decoration: none;
    display: inline-block;
    width: 100%;
    padding: 20px;
    border-bottom: 1px solid #f9f9f9;
}
.tickets-list .ticket-item.ticket-more {
    padding: 15px;
    text-align: center;
    font-weight: 600;
    font-size: 12px;
}
.tickets-list .ticket-item .ticket-title h4 {
    font-size: 16px;
    font-weight: 700;
}
.tickets-list .ticket-item .ticket-info {
    display: flex;
    font-size: 12px;
    font-weight: 500;
    color: #34395e;
    letter-spacing: .5px;
}
.tickets-list .ticket-item .ticket-info .bullet {
    margin: 0 10px;
}
.tickets {
    display: flex;
}
.tickets .ticket-items {
    width: 30%;
    padding-right: 30px;
}
.tickets .ticket-items .ticket-item {
    display: inline-block;
    width: 100%;
    padding: 25px 15px;
    border-bottom: 1px solid #f9f9f9;
    cursor: pointer;
    transition: all .5s;
}
.tickets .ticket-items .ticket-item:hover {
    background-color: rgba(63, 82, 227, 0.03);
}
.tickets .ticket-items .ticket-item:hover .ticket-title {
    color: #563d7c;
}
.tickets .ticket-items .ticket-item.active {
    box-shadow: 0 2px 6px #7a58ad;
    border-radius: 3px;
    background-color: #563d7c;
    border-bottom: none;
}
.tickets .ticket-items .ticket-item.active .ticket-title, .tickets .ticket-items .ticket-item.active .ticket-desc {
    color: #fff !important;
}
.tickets .ticket-items .ticket-item .ticket-title h4 {
    font-size: 13px;
    letter-spacing: .3px;
}
.tickets .ticket-items .ticket-item .ticket-title h4 .badge {
    padding: 7px 10px;
    margin-left: 5px;
}
.tickets .ticket-items .ticket-item .ticket-desc {
    display: flex;
    font-size: 11px;
    font-weight: 500;
    color: #34395e;
    letter-spacing: .5px;
}
.tickets .ticket-items .ticket-item .ticket-desc .bullet {
    margin: 0 10px;
}
.tickets .ticket-content {
    width: 70%}
.tickets .ticket-content .ticket-header {
    display: flex;
}
.tickets .ticket-content .ticket-header .ticket-sender-picture {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    overflow: hidden;
    margin-right: 20px;
}
.tickets .ticket-content .ticket-header .ticket-sender-picture img {
    width: 100%}
.tickets .ticket-content .ticket-header .ticket-detail .ticket-title h4 {
    font-size: 18px;
    font-weight: 700;
}
.tickets .ticket-content .ticket-header .ticket-detail .ticket-info {
    display: flex;
    letter-spacing: .3px;
    font-size: 12px;
    font-weight: 500;
    color: #34395e;
}
.tickets .ticket-content .ticket-header .ticket-detail .ticket-info .bullet {
    margin: 0 10px;
}
.tickets .ticket-divider {
    height: 1px;
    width: 100%;
    display: inline-block;
    background-color: #f2f2f2;
}
.tickets .ticket-description {
    color: #34395e;
    font-weight: 500;
    margin-top: 30px;
    line-height: 28px;
}
.tickets .ticket-description p {
    margin-bottom: 20px;
}
.tickets .ticket-description .ticket-form {
    margin-top: 40px;
}
.tickets .ticket-description .ticket-form .note-editable {
    color: #34395e;
    font-weight: 500;
}
.tickets .ticket-description .ticket-form .note-editable p {
    margin-bottom: 5px;
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .tickets {
    display: inline-block;
}
.tickets .ticket-items {
    width: 100%;
    margin-bottom: 30px;
    padding: 0;
    display: none;
}
.tickets .ticket-content {
    width: 100%}
}@media (min-width: 768px) and (max-width: 991.98px) {
    .tickets {
    flex-wrap: wrap;
    margin: 0 -15px;
}
.tickets .ticket-items {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding: 0;
    margin-bottom: 15px;
    padding: 15px;
    overflow: auto;
}
.tickets .ticket-items .ticket-item {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
}
.tickets .ticket-content {
    margin: 15px;
    width: 100%}
}.owl-theme .owl-item {
    padding: 10px 0;
}
.owl-theme .owl-dots {
    margin-top: 20px !important;
}
.owl-theme .owl-dots .owl-dot.active span {
    background-color: #563d7c;
}
.activities {
    display: flex;
    flex-wrap: wrap;
}
.activities .activity {
    width: 100%;
    display: flex;
    position: relative;
}
.activities .activity:before {
    content: ' ';
    position: absolute;
    left: 25px;
    top: 0;
    width: 2px;
    height: 100%;
    background-color: #563d7c;
}
.activities .activity:last-child:before {
    display: none;
}
.activities .activity .activity-icon {
    width: 50px;
    height: 50px;
    border-radius: 3px;
    line-height: 50px;
    font-size: 20px;
    text-align: center;
    margin-right: 20px;
    border-radius: 50%;
    flex-shrink: 0;
    text-align: center;
    z-index: 1;
}
.activities .activity .activity-detail {
    background-color: #fafbfe;
    border-radius: 3px;
    border: 1px solid #dae1f4;
    position: relative;
    margin-bottom: 30px;
    position: relative;
    padding: 15px;
}
.activities .activity .activity-detail:before {
    content: '\f0d9';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 20px;
    position: absolute;
    left: -8px;
    color: #fff;
}
.activities .activity .activity-detail h4 {
    font-size: 18px;
    color: #3B3D55;
}
.activities .activity .activity-detail p {
    margin-bottom: 0;
}
.invoice {
    background-color: #fafbfe;
    border-radius: 3px;
    border: 1px solid #dae1f4;
    position: relative;
    margin-bottom: 30px;
    padding: 40px;
}
.invoice .invoice-title .invoice-number {
    float: right;
    font-size: 20px;
    font-weight: 700;
    margin-top: -45px;
}
.invoice hr {
    margin-top: 40px;
    margin-bottom: 40px;
    border-top-color: #f9f9f9;
}
.invoice .invoice-detail-item {
    margin-bottom: 15px;
}
.invoice .invoice-detail-item .invoice-detail-name {
    letter-spacing: .3px;
    color: #98a6ad;
    margin-bottom: 4px;
}
.invoice .invoice-detail-item .invoice-detail-value {
    font-size: 18px;
    color: #34395e;
    font-weight: 700;
}
.invoice .invoice-detail-item .invoice-detail-value.invoice-detail-value-lg {
    font-size: 24px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
    .table-invoice table {
    min-width: 800px;
}
}.empty-state {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px;
}
.empty-state .empty-state-icon {
    position: relative;
    background-color: #563d7c;
    width: 80px;
    height: 80px;
    line-height: 100px;
    border-radius: 5px;
}
.empty-state .empty-state-icon i {
    font-size: 40px;
    color: #fff;
    position: relative;
    z-index: 1;
}
.empty-state h2 {
    font-size: 20px;
    margin-top: 30px;
}
.empty-state p {
    font-size: 16px;
}
.pricing {
    background-color: #fafbfe;
    border-radius: 3px;
    border: 1px solid #dae1f4;
    position: relative;
    margin-bottom: 30px;
    text-align: center;
}
.pricing.pricing-highlight .pricing-title {
    background-color: #563d7c;
    color: #fff;
}
.pricing.pricing-highlight .pricing-cta a {
    background-color: #563d7c;
    color: #fff;
}
.pricing.pricing-highlight .pricing-cta a:hover {
    background-color: #3e2c5a !important;
}
.pricing .pricing-padding {
    padding: 40px;
}
.pricing .pricing-title {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    background-color: #ebeef0;
    color: #563d7c;
    border-radius: 0 0 3px 3px;
    display: inline-block;
    padding: 5px 15px;
}
.pricing .pricing-price {
    margin-bottom: 45px;
}
.pricing .pricing-price div:first-child {
    font-weight: 600;
    font-size: 50px;
}
.pricing .pricing-details {
    text-align: left;
    display: inline-block;
}
.pricing .pricing-details .pricing-item {
    display: flex;
    margin-bottom: 15px;
}
.pricing .pricing-details .pricing-item .pricing-item-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    border-radius: 50%;
    text-align: center;
    background-color: #5CB85C;
    color: #fff;
    margin-right: 10px;
}
.pricing .pricing-details .pricing-item .pricing-item-icon i {
    font-size: 11px;
}
.pricing .pricing-cta {
    margin-top: 20px;
}
.pricing .pricing-cta a {
    display: block;
    padding: 20px 40px;
    background-color: #ebeef0;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    border-radius: 0 0 3px 3px;
}
.pricing .pricing-cta a .fas, .pricing .pricing-cta a .far, .pricing .pricing-cta a .fab, .pricing .pricing-cta a .fal, .pricing .pricing-cta a .ion {
    margin-left: 5px;
}
.pricing .pricing-cta a:hover {
    background-color: #DCE1E5;
}
.hero {
    border-radius: 3px;
    padding: 55px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
}
.hero.hero-bg-image {
    background-position: center;
    background-size: cover;
}
.hero.hero-bg-image:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    border-radius: 3px;
}
.hero.hero-bg-image.hero-bg-parallax {
    background-attachment: fixed;
}
.hero .hero-inner {
    position: relative;
    z-index: 1;
}
.hero h2 {
    font-size: 24px;
}
.hero p {
    margin-bottom: 0;
    font-size: 16px;
    letter-spacing: .3px;
}
.avatar {
    background: #563d7c;
    border-radius: 50%;
    color: #DCE1E5;
    display: inline-block;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    position: relative;
    vertical-align: middle;
    line-height: 1.28;
    height: 45px;
    width: 45px;
}
.avatar.avatar-xs {
    font-size: 6px;
    height: 15px;
    width: 15px;
}
.avatar.avatar-sm {
    font-size: 12px;
    height: 30px;
    width: 30px;
}
.avatar.avatar-lg {
    font-size: 23px;
    height: 60px;
    width: 60px;
}
.avatar.avatar-xl {
    font-size: 30px;
    height: 75px;
    width: 75px;
}
.avatar img {
    border-radius: 50%;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 1;
}
.avatar .avatar-icon {
    background: #fff;
    bottom: 14.64%;
    height: 50%;
    padding: .1rem;
    position: absolute;
    right: 14.64%;
    transform: translate(50%,  50%);
    width: 50%;
    z-index: 2;
}
.avatar .avatar-presence {
    background: #fff;
    bottom: 14.64%;
    height: 50%;
    padding: .1rem;
    position: absolute;
    right: 14.64%;
    transform: translate(50%,  50%);
    width: 50%;
    z-index: 2;
    background: #bcc3ce;
    border-radius: 50%;
    box-shadow: 0 0 0 .1rem #fff;
    height: .5em;
    width: .5em;
}
.avatar .avatar-presence.online {
    background: #5CB85C;
}
.avatar .avatar-presence.busy {
    background: #D9534F;
}
.avatar .avatar-presence.away {
    background: #F0AD4E;
}
.avatar[data-initial]::before {
    color: currentColor;
    content: attr(data-initial);
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,  -50%);
    z-index: 1;
}
.wizard-steps {
    display: flex;
    margin: 0 -10px;
    margin-bottom: 60px;
    counter-reset: wizard-counter;
}
.wizard-steps .wizard-step {
    background-color: #fafbfe;
    border-radius: 3px;
    border: 1px solid #dae1f4;
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    padding: 30px;
    text-align: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 10px;
}
.wizard-steps .wizard-step:before {
    counter-increment: wizard-counter;
    content: counter(wizard-counter);
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    line-height: 21px;
    font-size: 10px;
    font-weight: 700;
    border-radius: 50%;
    background-color: #DCE1E5;
}
.wizard-steps .wizard-step.wizard-step-active {
    box-shadow: 0 2px 6px #7a58ad;
    background-color: #563d7c;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-active:before {
    background-color: #563d7c;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-success {
    background-color: #5CB85C;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-success:before {
    background-color: #5CB85C;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-danger {
    background-color: #D9534F;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-danger:before {
    background-color: #D9534F;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-warning {
    background-color: #F0AD4E;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-warning:before {
    background-color: #F0AD4E;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-info {
    background-color: #007bff;
    color: #fff;
}
.wizard-steps .wizard-step.wizard-step-info:before {
    background-color: #007bff;
    color: #fff;
}
.wizard-steps .wizard-step .wizard-step-icon .fas, .wizard-steps .wizard-step .wizard-step-icon .far, .wizard-steps .wizard-step .wizard-step-icon .fab, .wizard-steps .wizard-step .wizard-step-icon .fal, .wizard-steps .wizard-step .wizard-step-icon .ion {
    font-size: 34px;
    margin-bottom: 15px;
}
.wizard-steps .wizard-step .wizard-step-label {
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
}
@media (max-width: 575.98px) {
    .wizard-steps {
    display: block;
}
.wizard-steps .wizard-step {
    margin-bottom: 50px;
}
}
