
video {
    border: 5px solid #134544;
    border-radius: 10px;
}

a.goback {
    padding: 10px 0px;
    display: inline-block;
    color: #134544;
    font-weight: 600;
}